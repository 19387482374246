(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:RefereeList
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('recalcul', recalcul);

  function recalcul($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/recalcul/:idPartido', {idPartido: '@_idPartido'}, {
      update: {
        method: 'PUT'
      }, insert: {
        method: 'POST'
      },
      query: {
        method: 'GET',
        isArray: true
      }
    });
  }
}());